.signin-container {
  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(to bottom, #68B545 50%, #ffffff 50%);
  margin-top: -2rem;
  position: relative;
}

.custom-phone-input-wrapper input {
  border: none !important; 
}

.signup-form {
  min-width: 60rem;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.signup-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-group .icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  color: #ff7f50;
}

.form-group input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-radius: 0;
  background-color: transparent;
}

button[type='submit'] {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #ff7f50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

button[type='submit']:hover {
  background-color: #ff6347;
}

p {
  text-align: center;
  margin-top: 10px;
}

a {
  color: #ff7f50;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.toggle-password-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #68b545;
  font-size: 14px; /* Adjust the font size as needed */
}

.password-input {
  display: flex;
  align-items: center;
  position: relative;
}

.toggle-password-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

