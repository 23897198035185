.therapist-grandParent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.therapist-containerr{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}


.field-label{
  font-size: 1.5rem;
  text-align: left;
}

/* .parent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
} */

/* .therapist {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  width: 20%;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-delay: calc(var(--index) * 0.1s);
  animation-fill-mode: forwards;
} */

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.filter-container {
  background-color: #5179BD;
  border-radius: 5px;
  padding: 15px;
  color: white;
}

.filter-label {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.filter-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.filter-field {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.filter-field-label {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
  text-align: left;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
}

.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 5px;
}

.selected-options-container {
  margin-top: 25px;
}

.selected-options-label {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}

.selected-option {
  display: inline-flex;
  align-items: center;
  padding: 6px 10px;
  margin-right: 6px;
  margin-bottom: 6px;
  background-color: #ddd;
  border-radius: 20px;
  font-size: 14px;
}

.no-options {
  color: #999;
  font-style: italic;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .filter-container {
    padding: 12px;
  }

  .filter-label {
    font-size: 18px;
  }

  .filter-field-label {
    font-size: 14px;
  }

  .checkbox-label {
    font-size: 12px;
  }

  .selected-option {
    padding: 4px 8px;
    font-size: 12px;
  }
}

/* CSS for mobile screens (up to 767px width) */
@media (max-width: 767px) {
  .filter-container {
    width: 100%;
    max-width: 100%;
  }
}


/* TherapistsWithFilter.css */
.pagination {
  text-align: center;
  margin-top: 20px;
}

.pagination ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination li {
  background-color: #fff;
  color: #5179BD;
  border: 1px solid #5179BD;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s, transform 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}

.pagination li.active {
  background-color: #5179BD;
  color: #fff;
  border: 1px solid #fff;
  transform: scale(1.1); /* Add a 3D effect on hover */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a box shadow on hover */
}

.pagination li:hover {
  background-color: #5179BD; /* Change background color on hover */
  color: #fff; /* Change text color on hover */
  transform: scale(1.05); /* Add a hover effect */
}
.assessments-assessment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.assessmentintroPage{
  display: flex;
  min-height: 11.5rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: aquamarine;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #f0f0f0;
}

.progress {
  height: 100%;
  background-color: #4caf50;
  width: 0;
  transition: width 0.3s ease-in-out;
}


.options {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap within the container */
  justify-content: center;
  align-items: stretch; /* Stretch items vertically */
  margin-bottom: 20px;
  border: 1px solid #ccc; /* Add border */
  border-radius: 4px;
  padding: 10px; /* Add padding for better appearance */
  width:50%;
  margin:0 auto;
}

.assessments-assessment-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.assessments-question-number {
  font-size: 18px;
  margin-bottom: 10px;
}

.assessments-question {
  font-size: 16px;
  margin-bottom: 20px;
}

.assessments-options {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.options .assessments-option {
  background-color: #68B545 !important; /* Set background color to #68B545 */
}

.options .assessments-option.selected {
  background-color: #ccc !important; /* Set background color for selected option */
}

.options .option {
  background: rgba(104, 181, 69, 0.15);
}

.assessments-option {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 120px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #68B545;

}

.assessments-option.selected {
  background: #68B545;
}

.assessments-navigation-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.assessments-navigation-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.assessments-total-score {
  font-size: 18px;
  font-weight: bold;
}


.assessments-AssessmentCardsAvailable {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .assessments-AssessmentCardsAvailable {
    flex-direction: column;
    align-items: center;
  }
  
  .AssessmentCard {
    width: 90%;
    max-width: 300px;
    margin: 1rem;
  }
}
