/* AssessmentPage.css */

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  margin-bottom: 20px;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.option {
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.option.selected {
  background-color: #ccc;
}

.navigation-buttons {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  margin-top: 20px;
}

.navigation-buttons button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 10px; /* Add margin between buttons */
}

.navigation-buttons button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}


.navigation-buttons button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.navigation-buttons button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 16px;
  }

  p {
    font-size: 14px;
  }

  .options .option {
    padding: 8px;
  }

  .navigation-buttons button {
    padding: 8px 16px;
  }
}
