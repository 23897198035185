*{
  color: black;
}

.AssessmentCard {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 2rem;
  min-height: 15rem;
  opacity: 0;
  transform: rotateX(360deg);
  animation: flipAnimation 0.5s ease-in-out;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.AssessmentCard.loaded {
  opacity: 1;
  transform: rotateX(0);
  transition-delay: 0.2s; /* Delay the transition to create a fade-in effect */
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 16px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-content {
  text-align: left;
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 8px;
}

.description {
  font-size: 1rem;
  margin-bottom: 16px;
}

.button-container {
  display: flex;
  justify-content: center;
}

.assessment-link {
  width: 100%;
}

.Assessment-btn {
  padding: 8px 16px;
  background-color: #d67449;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.Assessment-btn:hover {
  background-color: #0056b3;
}
