.table-container {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 1;
}

.add-corporate-button {
  margin-top: 16px;
  margin-left: 16px;
}

.add-button {
  background-color: #d67449;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  margin-bottom: 1rem;
}

.add-button:hover {
  background-color: #ff915c;
}

.add-button:focus {
  outline: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.form-container {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  width: 90%; /* Adjust width for responsiveness */
  max-width: 400px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.form-container::before {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  background: linear-gradient(145deg, #ffffff, #e0e0e0);
  border-radius: 12px;
  z-index: -1;
}

.add-corporate-label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}

.form-container form {
  display: flex;
  flex-direction: column;
}

.form-container label {
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 14px;
}

.form-container input {
  margin-bottom: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-container button {
  background-color: #d67449;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  width: 100%;
}

.form-container button:hover {
  background-color: #ff915c;
}

.close-button {
  background-color: #ccc;
  color: white;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  align-self: flex-end;
}

.close-button:hover {
  background-color: #aaa;
}

.groups-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  margin-left: 16px;
}

.groups-table th,
.groups-table td {
  padding: 12px;
  text-align: center;
  word-wrap: break-word;
}

.groups-table th {
  font-weight: bold;
}

.group-image {
  max-width: 100px;
  max-height: 100px;
}

.group-url {
  text-decoration: none;
}

.details-button {
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: bold;
}

.file-upload-label {
  background-color: #d67449;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  width: 100%;
  text-align: center;
}

.file-upload-label:hover {
  background-color: #ff915c;
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .add-button {
    padding: 6px 12px;
  }

  .groups-table th,
  .groups-table td {
    padding: 10px;
    font-size: 14px;
  }

  .form-container {
    padding: 16px;
    width: 90%;
  }
}

@media screen and (max-width: 480px) {
  .groups-table th,
  .groups-table td {
    padding: 8px;
    font-size: 12px;
  }

  .add-button {
    padding: 4px 8px;
    font-size: 12px;
  }

  .form-container {
    padding: 12px;
    width: 90%;
  }
}
