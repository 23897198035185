.profile-container {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  background-color: #68b545;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.profile-title {
  font-size: 28px;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
}

.profile-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 10px;
}

.detail-item {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #fff;

  border-radius: 4px;
}

.detail-label {
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
  color: #555;
}

.detail-value {
  font-size: 16px;
  color: #333;
}

/* Increase the font size and add some padding to the toast messages */
.Toastify__toast {
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
}

/* Style for success messages */
.Toastify__toast--success {
  color: #fff;
}

/* Style for error messages */
.Toastify__toast--error {
  background-color: #f44336;
  color: #fff;
}
