/* CorporateUser.css */

/* Pagination styles */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.page-number {
  cursor: pointer;
  margin: 0 5px;
  padding: 8px 12px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  font-weight: bold;
  font-size: 14px; /* Adjust font size as needed */
  color: #333;
}

.page-number.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.page-number:hover {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}
