.booktime-title {
  flex-basis: 100%;
  color: black;
  text-align: left;
  margin: 1rem;
  margin-left: 0;
  font-weight: 800;
  margin-left: 3.3rem;
}


.booknowBtnLayout {
  display: flex;
  background: linear-gradient(90deg, #d67449 0.88%, #5179bd 100%);
  padding: 1.5rem 3rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 8rem;
  border-radius: 1rem;
  /* 3D effects */
  box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.15);
  perspective: 800px;
  transform-style: preserve-3d;
}

.booknowBtnLayout1,
.booknowBtnLayout2,
.booknowBtnLayout3 {
  flex: 1;
  padding: 1rem;
  text-align: center;
}

.booknowBtnLayouttextheading {
  font-size: 1.5rem;
  color: #ffffff;
  margin: 0;
}

.booknowBtnLayoutp {
  color: #ffffff;
  margin: 0;
}

.therapist-know-more-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #ffffff;
  color: #000000;
  text-decoration: none;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  /* 3D effects */
  transform: translateZ(0);
  perspective: 800px;
  backface-visibility: hidden;
}

.therapist-know-more-button:hover {
  background-color: #f5f5f5;
}




.booktime-pagination {
  display: flex;
  justify-content: flex-end;
}

* {
  box-sizing: border-box;
  font-family: Poppins;
}

.booktime-subDetails {
  font-size: 2rem;
}

.subDetails {
  font-size: 1.4rem; /* Default font size for desktop */

  @media (max-width: 767px) {
    /* Media query for screens up to 767px width (considered mobile) */
    font-size: 0.9rem; /* Font size for mobile */
  }
}


.booktime-profileIcons {
  padding: 0.4rem;
}

.booktime-lastUserDetailRow {
  font-size: 1rem;
}

.lastUserDetailRow{
  font-size: 1rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 5rem;
}

.lastUserDetailRow1{
  font-size: 1rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 3.3rem;
}

.booktime-containerr2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .booktime-profileDetails {
    flex: 1 1 50%;
    max-width: 50%;
    padding: 0.3rem;
  }
}

@media (max-width: 480px) {
  .booktime-profileDetails {
    flex: 1 1 100%;
    max-width: 100%;
    padding: 0.2rem;
  }
}

.booktime-profileDetails {
  max-width: 25%;
  min-width: 25%;
  box-sizing: border-box;
  padding: 0.5rem;
  /* 3D effects */
  box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.15);
  perspective: 80rem;
  transform-style: preserve-3d;
}

.booktime-container1 {
  display: flex;
  background-color: #68b545;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* 3D effects */
  box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.15);
  perspective: 800px;
  transform-style: preserve-3d;
}

.booktime-imgDiv {
  flex-basis: 25rem;
  margin-right: 0.2rem;
  max-width: 80%;
  max-height: 100%;
  justify-content: center;
}

.booktime-doctorImg {
  margin-left: -1rem;
  margin-right: -5rem;
  margin-top: 1%;
  max-width: 55%;
  min-width: 40%;
  height: auto;
  border-radius: 5%;
  min-width: 12%;
}

.booktime-aboutDiv {
  display: flex;
  width: 69%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: -3rem;
}

.booktime-parentcard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.booktime-containerr {
  display: flex;
  margin-left: 3rem;
  max-width: 100%;
  min-width: 20%;
  width: 100rem;
}

.booktime-miniContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.booktime-dateColumn {
  color: black;
  font-size: 1.5rem;
  max-width: 11rem;
}

.booktime-dateSelect {
  background-color: #5179bd;
  color: white;
  padding: 1rem;
  margin: 0.3rem;
  border-radius: 0.7rem;
  font-size: 1rem;
}

.booktime-time {
  background: rgba(104, 181, 69, 0.25);
  margin: 0.3rem;
  font-size: 1rem;
  padding: 1rem;
  height: 3rem;
  align-items: center;
  border-radius: 1rem;
  min-width: 9rem;
  /* 3D effects */
  box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.15);
  perspective: 800px;
  transform-style: preserve-3d;
}

.booktime-time:hover {
  background-color: #68b545;
}

@media (max-width: 768px) {
  .booktime-container1 {
    flex-direction: column;
  }

  .booktime-imgDiv {
    flex-basis: 100%;
  }

  .aboutDiv {
    width: 100%;
    height: 20%;
    align-items: center;
  }

  .aboutDiv p {
    height: 50%;
    text-align: center;
  }

  .div2 {
    width: 100%;
    margin-top: 2rem;
    text-align: center;
    height: 50%;
  }
}

.education {
  flex: 1;
}

.achievement {
  flex: 1;
}

.offering {
  display: flex;
  justify-content: space-between;
  border: 1.5px solid #68b545;
  border-radius: 25px;
  max-width: 80rem;
  margin-left: 7rem;
  align-items: center;
  cursor: pointer;
  /* 3D effects */
  box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.15);
  perspective: 800px;
  transform-style: preserve-3d;
}

.allOfferings {
  display: none;
  margin-top: 1rem;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transform: scale(0.9);
}

.offering.active + .allOfferings {
  display: block;
  opacity: 1;
  transform: scale(1);
}

.addMinusimg {
  height: 1rem;
  width: 1rem;
  margin-right: 1rem;
  margin-bottom: 0.3rem;
}

.offeringtext {
  color: black;
  text-align: center;
  margin-left: 3rem;
  font-size: small;
}

.offeringtext {
  font-size: 1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 36px;
}

@media (max-width: 768px) {
  .booktime-containerr2 {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
}

.price-options {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.price-option {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.price-option:hover {
  background-color: #f5f5f5;
}

.price-option.selected {
  background-color: #68b545;
  color: white;
  border-color: #68b545;
}

.price-header {
  font-size: 18px;
  font-weight: bold;
}

.price-value {
  margin-top: 10px;
  font-size: 14px;
}

.mrp {
  color: #777;
}

.discounted-price {
  color: black;
}

/* Custom CSS for error toasts */
.custom-toast-error {
  background-color: white; /* Change the background color to white */
  color: red; /* Change the text color to red */
  /* Add any other custom styles you need */
}


.lastUserDetailRow {
  font-size: 1.2rem; /* Default font size for desktop */

  @media (max-width: 767px) {
    /* Media query for screens up to 767px width (considered mobile) */
    font-size: 0.8rem; /* Font size for mobile */
  }

  text-align: center;
}
