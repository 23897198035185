.centerTherapistsDetails {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.therapistHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #68b545;
  padding: 1rem;
  position: relative;
}

.therapistDetailsDiv {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  perspective: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  transform-origin: center;
  transform: rotateY(0);
}

.imageContainer:hover {
  transform: rotateY(10deg);
}

.therapistImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.inspironWheel {
  position: absolute;
  width: 7rem;
  left: 1px;
}

.therapistsName {
  font-size: 2rem;
  color: white;
}

.therapistsDetails {
  font-size: 1rem;
  color: white;
}

.tehrapistsSessionsDetails {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.tehrapistsSessionsDetailsDivs1 {
  width: 25rem;
  height: 5rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sessions1 {
  background-color: #5179bd;
}

.sessions2 {
  background-color: #43aeb4;
  margin: 1rem;
}

.sessions3 {
  background-color: #4690b4;
}

.sessionCount {
  font-size: 2rem;
  color: white;
  font-weight: bold;
}

.sessionLabel {
  font-size: 1rem;
  color: white;
}

/* Table Styles */
.table-container {
  width: 100%;
  overflow-x: auto;
  margin: 20px 0;
}

.today-appointments-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  max-width: 80rem;
  margin: 20px auto;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-size: 14px;
}

/* Table Header Styles */
.table-header-cell {
  background-color: #5179bd;
  color: #ffffff;
  font-weight: bold;
  padding: 8px;
  height: 4rem;
  font-size: 1rem;
  text-align: center;
}

/* Table Body Styles */
.table-body-row:nth-child(even) {
  background-color: #f5f5f5;
}

.table-body-cell {
  padding: 8px;
  text-align: center;
}

.table-body-row:hover {
  background-color: #e8f0fe;
}

/* Button Styles */
.button {
  border: none;
  background-color: #f8f8f8;
  color: #333;
  padding: 6px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.button:hover {
  background-color: #e0e0e0;
}

.button-icon {
  margin-right: 4px;
}

/* 3D Box Shadow Effect */
.table-body-row:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.button:hover,
.button:focus {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  transform: translateY(-1px);
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .therapistHeader {
    flex-direction: row;
    padding: 0.5rem;
  }
  .therapistsName {
    font-size: 1.5rem;
  }
  .therapistsDetails {
    font-size: 0.875rem;
  }
  .tehrapistsSessionsDetailsDivs1 {
    width: 20rem;
    height: 4rem;
  }
  .sessionCount {
    font-size: 1.5rem;
  }
  .sessionLabel {
    font-size: 0.875rem;
  }
}

@media (max-width: 768px) {
  .therapistHeader {
    flex-direction: column;
  }
  .therapistsName {
    font-size: 1.25rem;
  }
  .therapistsDetails {
    font-size: 0.75rem;
  }
  .tehrapistsSessionsDetailsDivs1 {
    width: 15rem;
    height: 3.5rem;
  }
  .sessionCount {
    font-size: 1.25rem;
  }
  .sessionLabel {
    font-size: 0.75rem;
  }
}

@media (max-width: 480px) {
  .therapistHeader {
    padding: 0.25rem;
  }
  .therapistsName {
    font-size: 1rem;
  }
  .therapistsDetails {
    font-size: 0.625rem;
  }
  .tehrapistsSessionsDetailsDivs1 {
    width: 12rem;
    height: 3rem;
  }
  .sessionCount {
    font-size: 1rem;
  }
  .sessionLabel {
    font-size: 0.625rem;
  }
  .today-appointments-table {
    font-size: 12px;
  }
  .table-header-cell,
  .table-body-cell {
    padding: 6px;
  }
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .therapistHeader {
    flex-direction: row;
    padding: 0.5rem;
  }
  .therapistsName {
    font-size: 1.5rem;
  }
  .therapistsDetails {
    font-size: 0.875rem;
  }
  .tehrapistsSessionsDetailsDivs1 {
    width: 20rem;
    height: 4rem;
  }
  .sessionCount {
    font-size: 1.5rem;
  }
  .sessionLabel {
    font-size: 0.875rem;
  }
}

@media (max-width: 768px) {
  .therapistHeader {
    flex-direction: column;
  }
  .therapistsName {
    font-size: 1.25rem;
  }
  .therapistsDetails {
    font-size: 0.75rem;
  }
  .tehrapistsSessionsDetailsDivs1 {
    width: 15rem;
    height: 3.5rem;
  }
  .sessionCount {
    font-size: 1.25rem;
  }
  .sessionLabel {
    font-size: 0.75rem;
  }
}

@media (max-width: 480px) {
  .therapistHeader {
    padding: 0.25rem;
  }
  .therapistsName {
    font-size: 1rem;
  }
  .therapistsDetails {
    font-size: 0.625rem;
  }
  .tehrapistsSessionsDetailsDivs1 {
    width: 12rem;
    height: 3rem;
  }
  .sessionCount {
    font-size: 1rem;
  }
  .sessionLabel {
    font-size: 0.625rem;
  }
  .today-appointments-table {
    font-size: 12px;
  }
  .table-header-cell,
  .table-body-cell {
    padding: 6px;
  }
}

