.table-container {
  overflow-x: auto;
  margin-top: 20px;
}

.table-header-cell,
.table-body-cell {
  padding: 10px;
  text-align: left;

}

/* Removed background-color and font-weight changes */
.table-body-row:hover {
  background-color: #f9f9f9;
}

.pagination-controls {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.pagination-button {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: inherit; /* Inherit background color */
  border: 1px solid #ccc;
  cursor: pointer;
}

.pagination-button.active {
  background-color: #5179bd;
  color: white;
}

.pagination-button:disabled {
  cursor: not-allowed;
  background-color: #ddd;
}
.table-container {
  width: 100%;
  overflow-x: auto;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-header-cell,
.table-body-cell {
  padding: 8px;
  text-align: left;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .table-header-cell, .table-body-cell {
    padding: 6px;
    font-size: 14px;
  }

  .details-button, .fill-prescription-button {
    padding: 6px;
    font-size: 14px;
  }
}
