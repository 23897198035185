.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.forgot-password-form {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.subtext {
  font-size: 14px;
  color: #777;
  margin-bottom: 20px;
}

.icon {
  font-size: 24px;
  color: #333;
}

.warning {
  color: #e74c3c;
  font-size: 14px;
  margin-top: 5px;
}

input[type="email"] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 15px;
}

button[type="submit"] {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #2980b9;
}

/* Media queries for tablet and larger screens */
@media (min-width: 768px) {
  .forgot-password-form {
    padding: 40px;
  }

  h2 {
    font-size: 30px;
  }

  .subtext {
    font-size: 16px;
  }
}

/* Media queries for desktop screens */
@media (min-width: 992px) {
  .forgot-password-form {
    padding: 50px;
  }
}

/* Media queries for larger desktop screens */
@media (min-width: 1200px) {
  .forgot-password-form {
    padding: 60px;
  }
}
